@import '../../scss/main';
// Might be better to actually include the noscript in the AppShell itself?
@import '../../components/modal/FullScreenErrorModal';
@import '../../components/modal/FullScreenModal';
@import '../../components/modal/Modal';
@import '../../components/modal/ModalActionsList';
@import '../../components/loaders/LogoSpinner';
@import '../../workspace/toolbar/components/Toolbar';

// This is required for the transition period when the javascript takes over
.App {
    height: 100%;

    &.in-iframe {
        border: 1px solid var(--ws-default-canvas-background);
    }
}

.AppShell {
    display: block;

    .AppShellHeader {
        position: relative;

        height: var(--app-header-tools-section-height);
        background: var(--ui-background-primary);
        box-shadow: var(--shadow-app-header);
        z-index: var(--z-index-header);
    }

    .Toolbar {
        height: 100%;

        .app-size-small & {
            width: $toolbar-width-small;
        }

        .app-size-medium & {
            width: $toolbar-width-medium;
        }

        .app-size-mobile & {
            display: none;
        }
    }
}
